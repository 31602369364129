import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Stack, Typography } from '@mui/material';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import { Instagram, Language, PhoneInTalk } from '@mui/icons-material';
import { FaXTwitter } from "react-icons/fa6";

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] },
    {
        customer: [
            "code",
            "name",
            {
                operation: `isMobileHidden(code: WAYBILL)`,
                fields: [],
                variables: {
                },
            },
            {
                zone: ["id", "code", "name"]
            },
            {
                subzone: ["id", "code", "name"]
            },
            "phone",
            "mobile"
        ],
    },
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderPhone",
            "senderMobile",
            "refNumber",
            "notes",
            "date",
            {
                type: ["name", "code"],
            },
            {
                originBranch: ["name"],
            },
            {
                customer: [
                    "code",
                    {
                        operation: `isMobileHidden(code: WAYBILL)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
            {
                openable: ["code"],
            },

            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["name"],
            },
            {
                senderSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    printSticky: `${PREFIX}-printSticky`,
    header: `${PREFIX}-header`,
    logoImg: `${PREFIX}-logoImg`,
    bold: `${PREFIX}-bold`,
    customFontSize: `${PREFIX}-customFontSize`,
    hideSenderPhone: `${PREFIX}-hideSenderPhone`,
    stickyCode: `${PREFIX}-stickyCode`,
    customLines: `${PREFIX}-customLines`,
    fieldStyle: `${PREFIX}-fieldStyle`,
    mobileStyle: `${PREFIX}-mobileStyle`,
};

export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.printSticky}`]: {
        position: "relative",
        pageBreakInside: "avoid",
        width: "calc(10cm - (2px + 1mm))",
        height: "calc(15cm - (2px + 2mm))",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        boxSizing: "border-box",
        lineHeight: "1.2",
    },
    [`& .${classes.header}`]: {
        height: "1.2cm"
    },
    [`& .${classes.logoImg}`]: {
        maxHeight: "80%",
        maxWidth: "fit-content"
    },
    [`& .${classes.mobileStyle}`]: {
        direction: "rtl",
        width: "fit-content"
    },
    [`& .${classes.bold}`]: {
        fontWeight: 700,
        [`& p`]: {
            fontWeight: 700,
        },
    },
    [`& .${classes.customFontSize}`]: {
        fontSize: "11px",
    },
    [`& .${classes.fieldStyle}`]: {
        height: 35,
        border: "1px solid"
    },
    [`& .${classes.hideSenderPhone}`]: {
        display: "none !important",
    },
    [`& .${classes.stickyCode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        overflow: "hidden",
        fontSize: 45,
    },
    [`& .${classes.customLines}`]: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1, /* number of lines to show */
        lineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
}));


function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 10cm 15cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template10X15 = (props) => {
    const {
        loading,
        validData,
        settingsLoad,
        parsedData,
        isBatch,
        customer,
        currency,
        LogoImg
    } = props

    const { t } = useTranslation()

    const getSenderMobiles = (shipment) => {
        return customer ? customer?.mobile :
            shipment?.senderMobile
    }

    useEffect(() => {
        createDynamicStyles()
    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) || settingsLoad ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />
                </>
            ) : (
                parsedData.map((shipment, index) => {
                    return (
                        <Root key={index}>
                            <div
                                className={clsx({
                                    [classes.printSticky]: !loading && validData,
                                })}
                                style={{ lineHeight: "1.2 !important", position: "relative" }}
                            >
                                {/* Header */}
                                <Stack
                                    justifyContent={"space-between"}
                                    direction={"row"}
                                    px={1}
                                    className={clsx(
                                        classes.header,
                                        classes.bold
                                    )}
                                    pt={0.2}
                                >
                                    <Stack
                                        height={"100%"}
                                        justifyContent={"flex-start"}
                                    >
                                        <Typography className={classes.customFontSize}>ﺧﺪﻣﺎت اﻟﺸﺤﻦ واﻟﺘﻮﺻﻴـﻞ</Typography>
                                        <Typography sx={{ letterSpacing: "0.7px" }} className={classes.customFontSize}>DELIVERY & SHIPPING</Typography>
                                    </Stack>
                                    <Stack
                                        height={"100%"}
                                        fontWeight={"bold"}
                                        justifyContent={"flex-start"}
                                    >
                                        <LogoImg forceLight={true} className={classes.logoImg} />
                                    </Stack>
                                </Stack>
                                {/* End Header */}

                                <Stack p={2} spacing={1.6}>
                                    <Typography className={classes.bold}>اﻟﻤﺮﺳــﻞ / Shipper</Typography>
                                    <Stack direction={"row"}>
                                        <Stack spacing={-0.3} width={"15%"}>
                                            <Typography className={classes.customFontSize}>الإسم</Typography>
                                            <Typography className={classes.customFontSize}>Name</Typography>
                                        </Stack>
                                        <Stack className={classes.fieldStyle} width={"85%"} p={1} justifyContent={"center"} >
                                            <Typography className={clsx(classes.customFontSize, classes.customLines)}>
                                                {customer ? customer.name : shipment?.senderName}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        <Stack spacing={-0.3} width={"15%"}>
                                            <Typography className={classes.customFontSize}>اﻟـــﺮﻗــﻢ</Typography>
                                            <Typography className={classes.customFontSize}>Number</Typography>
                                        </Stack>
                                        <Stack className={classes.fieldStyle} width={"85%"} p={1} justifyContent={"center"}>
                                            <Typography
                                                className={
                                                    clsx(classes.customFontSize, classes.mobileStyle,
                                                        {
                                                            [classes.hideSenderPhone]: customer ? customer.isMobileHidden : shipment?.customer?.isMobileHidden
                                                        }
                                                    )}
                                            >
                                                {getSenderMobiles(shipment)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography className={classes.bold}>اﻟﻤﺴﺘﻠــﻢ / Receiver</Typography>
                                    <Stack direction={"row"} spacing={1} width={"100%"}>
                                        <Stack direction={"row"} width={"100%"}>
                                            <Stack spacing={-0.3} width={"31%"}>
                                                <Typography className={classes.customFontSize}>الإسم</Typography>
                                                <Typography className={classes.customFontSize}>Name</Typography>
                                            </Stack>
                                            <Stack className={classes.fieldStyle} width={"69%"} p={1} justifyContent={"center"}>
                                                <Typography className={clsx(classes.customFontSize, classes.customLines)}>
                                                    {shipment?.recipientName}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} width={"100%"}>
                                            <Stack spacing={-0.3} width={"31%"}>
                                                <Typography className={classes.customFontSize}>اﻟـــﺮﻗــﻢ</Typography>
                                                <Typography className={classes.customFontSize}>Number</Typography>
                                            </Stack>
                                            <Stack className={classes.fieldStyle} width={"69%"} p={1} justifyContent={"center"}>
                                                <Typography className={clsx(classes.customFontSize, classes.mobileStyle,)}>
                                                    {isBatch ? ' ' : shipment?.recipientMobile}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1} width={"100%"}>
                                        <Stack direction={"row"} width={"100%"}>
                                            <Stack spacing={-0.3} width={"31%"}>
                                                <Typography className={classes.customFontSize}>اﻟـﻮﻻﻳـﺔ</Typography>
                                                <Typography className={classes.customFontSize}>Wilayat</Typography>
                                            </Stack>
                                            <Stack className={classes.fieldStyle} width={"69%"} p={1} justifyContent={"center"}>
                                                <Typography className={clsx(classes.customFontSize, classes.customLines)} >
                                                    {!isBatch && (shipment?.recipientSubzone?.name)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} width={"100%"}>
                                            <Stack spacing={-0.3} width={"31%"}>
                                                <Typography className={classes.customFontSize}>اﻟﻤﻨﻄﻘﺔ</Typography>
                                                <Typography className={classes.customFontSize} sx={{ letterSpacing: "3.5px" }}>Area</Typography>
                                            </Stack>
                                            <Stack className={classes.fieldStyle} width={"69%"} p={1} justifyContent={"center"}>
                                                <Typography className={clsx(classes.customFontSize, classes.customLines)}>
                                                    {shipment?.recipientAddress}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        <Stack spacing={-0.3} width={"15%"}>
                                            <Typography className={classes.customFontSize}>اﻟﻤﺒﻠﻎ</Typography>
                                            <Typography className={classes.customFontSize} sx={{ letterSpacing: "2px" }}>COD</Typography>
                                        </Stack>
                                        <Stack className={classes.fieldStyle} width={"85%"} p={1} justifyContent={"center"}>
                                            <Stack direction={"row"} spacing={0.3}>
                                                <Typography className={classes.customFontSize}>
                                                    {shipment?.totalAmount}
                                                </Typography>
                                                <Typography className={classes.customFontSize}>
                                                    {!isBatch && currency}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Typography className={classes.bold}>ﻣﻼﺣﻈﺎت أﺧﺮى / Additional Notes</Typography>
                                    <Stack className={classes.fieldStyle} width={"100%"} p={1} justifyContent={"center"} height={35}>
                                        <Typography className={clsx(classes.customLines, classes.customFontSize)}>
                                            {shipment?.notes}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        className={clsx(classes.stickyCode)}
                                    >
                                        *{shipment.code}*
                                    </Stack>
                                    <Stack
                                        direction={"row"} justifyContent={"space-around"}
                                        sx={{ position: "absolute", bottom: 0, width: "100%", left: 0 }}
                                        px={2}
                                    >
                                        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                                            <Typography className={classes.customFontSize}>
                                                www.wasl.om
                                            </Typography>
                                            <Language sx={{ fontSize: 13 }} />
                                        </Stack>
                                        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                                            <Typography className={classes.customFontSize}>
                                                wasl.omn
                                            </Typography>
                                            <Instagram sx={{ fontSize: 13 }} />
                                            <FaXTwitter sx={{ fontSize: 13 }} />
                                        </Stack>
                                        <Stack direction={"row"} alignItems={"center"} spacing={0.5} >
                                            <Typography className={classes.customFontSize}>
                                                72329044 968+
                                            </Typography>
                                            <PhoneInTalk sx={{ fontSize: 13 }} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </div>
                        </Root>
                    )
                })
            )}
        </Fragment>
    )
}

export default Template10X15